<template>
	<div>
		<el-form
			:model="baseInfo"
			ref="ruleForm"
			label-width="0"
			class="demo-ruleForm"
		>
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="基本信息" name="first">
					<table class="base-table">
						<tr>
							<th>企业名称</th>
							<td>{{ setterInfo.accountName }}</td>
							<th>统一信用代码</th>
							<td>{{ businessInfo.busiLicenseCode }}</td>
						</tr>
						<tr>
							<th>营业执照有效期</th>
							<td>{{ businessInfo.busiLicensePeriod }}</td>
							<th>所在城市</th>
							<td>
								<template v-if="!isUpdate"
									>{{
										baseInfo.provinceCode | provinceCityFM
									}}-{{
										baseInfo.cityCode
											| provinceCityFM(
												baseInfo.provinceCode
											)
									}}</template
								>
								<template v-else>
									<el-col :span="11">
										<el-form-item prop="provinceCode">
											<el-select
												v-model="baseInfo.provinceCode"
												placeholder="请选择省份"
												@change="provinceChange"
											>
												<el-option
													v-for="item in provinceList"
													:key="item.code"
													:label="item.name"
													:value="item.code"
												></el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<span
										style="float:left; display:block; padding:0 5px; line-height:40px;color:#999"
										>-</span
									>
									<el-col :span="11">
										<el-form-item prop="cityCode">
											<el-select
												v-model="baseInfo.cityCode"
												placeholder="请选择城市"
											>
												<el-option
													v-for="item in cityList"
													:key="item.code"
													:label="item.name"
													:value="item.code"
												></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</template>
							</td>
						</tr>
						<tr>
							<th>经营地址</th>
							<td colspan="3">
								<template v-if="!isUpdate">{{
									baseInfo.businessAddress
								}}</template>
								<template v-else
									><el-form-item prop="businessAddress"
										><el-input
											v-model="baseInfo.businessAddress"
										></el-input></el-form-item
								></template>
							</td>
						</tr>
						<tr>
							<th>法人代表姓名</th>
							<td>{{ baseInfo.legalName }}</td>
							<th>联系人手机号</th>
							<td>
								<template v-if="!isUpdate">
									{{
										hideContactFn(
											baseInfo.contactsMobilePhone
										)
									}}
								</template>
								<template v-else>
									<el-form-item prop="contactsMobilePhone"
										><el-input
											v-model="
												baseInfo.contactsMobilePhone
											"
											maxlength="11"
										></el-input></el-form-item
								></template>
							</td>
						</tr>
						<tr>
							<th>法人代表身份证号</th>
							<td>{{ maskIDNumber(baseInfo.legalIdCardNo) }}</td>
							<th>身份证有效期</th>
							<td>
								{{
									baseInfo.legalIdCardValidity == "0"
										? "长期"
										: baseInfo.legalIdCardValidity
								}}
							</td>
						</tr>
						<tr>
							<th>运营人员</th>
							<td>
								<template v-if="!isUpdate">{{
									baseInfo.operaterUserName
								}}</template>
								<template v-else>
									<el-form-item prop="operaterUserNo">
										<el-select
											v-model="baseInfo.operaterUserNo"
											placeholder="请选择运营人员"
										>
											<el-option
												v-for="item in operaterUserList"
												:key="item.id"
												:label="item.nickname"
												:value="item.userId"
											></el-option>
										</el-select>
									</el-form-item>
								</template>
							</td>
							<th>上游通道</th>
							<td>
								<div
									v-if="channelNoList && acqChannelList"
								></div>
								<span
									v-for="(item, index) in channelNoList"
									:key="item"
								>
									{{ acqChannelList[item] }}
									<span
										v-if="index < channelNoList.length - 1"
									>
										、
									</span>
								</span>
							</td>
						</tr>
					</table>
				</el-tab-pane>
				<el-tab-pane label="结算信息" name="second">
					<table class="base-table">
						<tr>
							<th>账号类型</th>
							<td>
								{{ setterInfo.accountType | accountTypeFM }}
							</td>
							<th>结算账户号码</th>
							<td>{{ setterInfo.accountNo }}</td>
						</tr>
						<tr>
							<th>开户名</th>
							<td>{{ setterInfo.accountName }}</td>
							<th>开户行城市</th>
							<td>
								{{
									setterInfo.accountProvince | provinceCityFM
								}}-{{
									setterInfo.accountCity
										| provinceCityFM(
											setterInfo.accountProvince
										)
								}}
							</td>
						</tr>
						<tr>
							<th>开户银行</th>
							<td>{{ setterInfo.bankName }}</td>
							<th>支行名称</th>
							<td>{{ setterInfo.branchName }}</td>
						</tr>
						<tr>
							<th>联行号</th>
							<td>{{ setterInfo.cnapsNo }}</td>
							<th></th>
							<td></td>
						</tr>
					</table>
				</el-tab-pane>
				<el-tab-pane label="图片信息" name="third">
					<div class="detail-img-box">
						<el-col :span="6" class="bs-img-item"
							><img :src="baseInfo.idCardNationalEmblemImg" />
							<p><em>*</em>法人代表人/负责人身份证国徽面</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="baseInfo.idCardFaceImg" />
							<p><em>*</em>法人代表人/负责人身份证人像面</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="businessInfo.licenseImg" />
							<p><em>*</em>营业执照照片</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="setterInfo.accountImg" />
							<p>
								<em>*</em
								>{{
									setterInfo.accountType == "2"
										? "开户许可证"
										: "法人代表银行卡正面照片"
								}}
							</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="businessInfo.premisesImg" />
							<p>经营场所室内照片</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="businessInfo.doorImg" />
							<p><em>*</em>经营场所门头照</p>
						</el-col>
						<el-col :span="6" class="bs-img-item"
							><img :src="businessInfo.securitiesImg" />
							<p><em>*</em>经营场所产权证明</p>
						</el-col>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-form>
		<!-- <el-row :gutter="10" style="padding:20px 0;text-align:center">
			<el-col :span="24">
				<el-button type="primary" icon="el-icon-edit" @click="upDateClick('ruleForm')" :loading="isLoading" > {{ isUpdate ? "保存" : "修改" }} </el-button>
			</el-col>
		</el-row> -->
	</div>
</template>
<script>
import { AgentApi, UserApi, TerminalApi } from "@/api";
export default {
	data() {
		return {
			activeName: "first",
			baseInfo: {},
			businessInfo: "",
			setterInfo: "",
			isUpdate: false,
			cityList: [],
			provinceList: [],
			operaterUserList: [],
			isLoading: false,
			agentNo: "",
			rules: {
				provinceCode: [
					{ required: true, message: "请选择省份", trigger: "change" }
				],
				cityCode: [
					{ required: true, message: "请选择城市", trigger: "change" }
				],
				businessAddress: [
					{
						required: true,
						message: "请输入经营地址",
						trigger: "blur"
					}
				],
				operaterUserNo: [
					{
						required: true,
						message: "请选择运营人员",
						trigger: "change"
					}
				],
				contactsMobilePhone: [
					{
						required: true,
						message: "请输入联系人手机号",
						trigger: "blur"
					},
					{
						pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
						message: "请输入正确的手机号码"
					}
				]
			},
			channelNoList: "",
			acqChannelList: ""
		};
	},
	created() {
		this.getDetail();
		UserApi.user.list(1, 2000).then(res => {
			if (res.success) {
				this.operaterUserList = res.data;
			}
		});
	},
	async mounted() {
		const result = await TerminalApi.acqChannel.listAllChannel();
		let obj = {};
		if (result) {
			result.data.map(i => {
				obj[i.channelNo] = i.name;
			});
		}
		this.acqChannelList = obj;
	},
	methods: {
		async getDetail() {
			this.agentNo = this.$route.query.agentNo;
			AgentApi.agentDetail(this.agentNo).then(res => {
				if (res.success) {
					this.baseInfo = res.data.baseInfo;
					this.businessInfo = res.data.businessInfo;
					this.setterInfo = res.data.setterInfo;
					this.channelNoList = res.data.channelNoList;
					AgentApi.listProvinceCity().then(res => {
						if (res.success) {
							this.provinceList = res.data;
							this.provinceList.map(e => {
								if (this.baseInfo.provinceCode == e.code) {
									this.cityList = e.children;
								}
							});
						}
					});
				}
			});
		},
		provinceChange(val) {
			this.baseInfo.cityCode = "";
			this.provinceList.map(e => {
				if (val == e.code) {
					this.cityList = e.children;
				}
			});
		},
		upDateClick(formName) {
			if (this.isUpdate) {
				this.isLoading = true;
				let params = {
					contactsMobilePhone: this.baseInfo.contactsMobilePhone,
					operaterUserNo: this.baseInfo.operaterUserNo,
					provinceCode: this.baseInfo.provinceCode,
					cityCode: this.baseInfo.cityCode,
					businessAddress: this.baseInfo.businessAddress
				};
				AgentApi.updateAgentBaseInfo(this.agentNo, params).then(res => {
					this.isLoading = false;
					this.isUpdate = res.success ? false : true;
					// this.$message('修改成功')
					this.getDetail();
				});
			} else {
				this.isUpdate = true;
			}
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return "";
			}
		},
		// 手机号脱敏
		hideContactFn(contact) {
			if (typeof contact === "string") {
				return contact.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
			} else {
				return "";
			}
		}
	}
};
</script>
